import React from "react";

export default function ThirdSection() {
  return (
    <div
      className="thirdsection lax"
      data-lax-opacity="0 0, 1900 1, 2000 1"
      //data-lax-translate-y_large="0 0, 1900 600, 2000 800"
    >
      <div
        className="light lax"
        data-lax-opacity="0 0, 1900 0.187, 2000 1"
        data-lax-translate-y="0 0, 1900 -10, 2300 20, 2700 400, 2800 300"
        data-lax-translate-x="0 1, 1900 100, 2300 -400, 2700 200, 2800 -200"
        data-lax-opacity_small="0 0, 1730 0.187, 1900 1"
        data-lax-translate-y_small="0 0, 1750 -10, 1810 20, 1990 200"
        data-lax-translate-x_small="0 1, 1750 0, 1810 -200, 1905 20, 2205 -200"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="198"
          height="197"
          viewBox="0 0 198 197"
          className="lightLayout"
        >
          <ellipse
            id="light"
            cx="99"
            cy="98.5"
            rx="99"
            ry="98.5"
            fill="#7b86a2"
            opacity="0.187"
          />
        </svg>
      </div>

      <div className="webarchitecture lax">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="688"
          height="745"
          viewBox="0 0 688 745"
          className="infrastructureWhole"
        >
          <g
            id="Gruppe_586"
            data-name="Gruppe 586"
            transform="translate(-417 -4378)"
          >
            <g
              id="Rechteck_65"
              data-name="Rechteck 65"
              transform="translate(1105 4378) rotate(90)"
              fill="none"
              stroke="#707070"
              strokeWidth="1"
              opacity="0.999"
              className="infrastructure"
            >
              <rect
                className="infrastructure recOut"
                width="745"
                height="688"
                rx="12"
                stroke="none"
              />
              <rect
                x="0.5"
                y="0.5"
                width="744"
                height="687"
                rx="11.5"
                fill="none"
                className="infrastructure"
              />
            </g>
            <g
              id="Ellipse_38"
              data-name="Ellipse 38"
              transform="translate(450 4404)"
              fill="none"
              stroke="#707070"
              strokeWidth="1"
              className="infrastructure"
            >
              <circle
                className="infrastructure"
                cx="10"
                cy="10"
                r="10"
                stroke="none"
              />
              <circle
                className="infrastructure"
                cx="10"
                cy="10"
                r="9.5"
                fill="none"
              />
            </g>
            <g
              id="Ellipse_39"
              data-name="Ellipse 39"
              transform="translate(484 4404)"
              fill="none"
              stroke="#707070"
              strokeWidth="1"
              className="infrastructure"
            >
              <circle
                className="infrastructure"
                cx="10"
                cy="10"
                r="10"
                stroke="none"
              />
              <circle
                className="infrastructure"
                cx="10"
                cy="10"
                r="9.5"
                fill="none"
              />
            </g>
            <g
              id="Ellipse_40"
              data-name="Ellipse 40"
              transform="translate(518 4404)"
              fill="none"
              stroke="#707070"
              strokeWidth="1"
              className="infrastructure"
            >
              <circle
                className="infrastructure"
                cx="10"
                cy="10"
                r="10"
                stroke="none"
              />
              <circle
                className="infrastructure"
                cx="10"
                cy="10"
                r="9.5"
                fill="none"
              />
            </g>
            <line
              id="Linie_8"
              data-name="Linie 8"
              x2="687"
              transform="translate(417.5 4450.5)"
              fill="none"
              stroke="#707070"
              strokeWidth="1"
              className="infrastructure"
            />
            <g
              id="Rechteck_66"
              data-name="Rechteck 66"
              transform="translate(582 4398)"
              fill="none"
              stroke="#707070"
              strokeWidth="1"
              className="infrastructure"
            >
              <rect width="499" height="35" rx="11" stroke="none" />
              <rect
                x="0.5"
                y="0.5"
                width="498"
                height="34"
                rx="10.5"
                fill="none"
                className="infrastructure"
              />
            </g>
            <g
              id="Rechteck_67"
              data-name="Rechteck 67"
              transform="translate(450 4507)"
              fill="none"
              stroke="#707070"
              strokeWidth="1"
              className="infrastructure"
            >
              <rect width="435" height="273" rx="32" stroke="none" />
              <rect
                x="0.5"
                y="0.5"
                width="434"
                height="272"
                rx="31.5"
                fill="none"
                className="infrastructure"
              />
            </g>
            <line
              id="Linie_9"
              data-name="Linie 9"
              x2="157.906"
              transform="translate(909.5 4573.5)"
              fill="none"
              stroke="#707070"
              strokeLinecap="round"
              strokeWidth="1"
              className="infrastructure"
            />
            <line
              id="Linie_10"
              data-name="Linie 10"
              x2="157.906"
              transform="translate(909.5 4602.5)"
              fill="none"
              stroke="#707070"
              strokeLinecap="round"
              strokeWidth="1"
              className="infrastructure"
            />
            <line
              id="Linie_11"
              data-name="Linie 11"
              x2="157.906"
              transform="translate(909.5 4631.5)"
              fill="none"
              stroke="#707070"
              strokeLinecap="round"
              strokeWidth="1"
              className="infrastructure"
            />
            <line
              id="Linie_12"
              data-name="Linie 12"
              x2="157.906"
              transform="translate(909.5 4660.5)"
              fill="none"
              stroke="#707070"
              strokeLinecap="round"
              strokeWidth="1"
              className="infrastructure"
            />
            <line
              id="Linie_13"
              data-name="Linie 13"
              x2="157.906"
              transform="translate(909.5 4689.5)"
              fill="none"
              stroke="#707070"
              strokeLinecap="round"
              strokeWidth="1"
              className="infrastructure"
            />
            <line
              id="Linie_14"
              data-name="Linie 14"
              x2="157.906"
              transform="translate(909.5 4718.5)"
              fill="none"
              stroke="#707070"
              strokeLinecap="round"
              strokeWidth="1"
              className="infrastructure"
            />
            <line
              id="Linie_15"
              data-name="Linie 15"
              x2="157.906"
              transform="translate(909.5 4747.5)"
              fill="none"
              stroke="#707070"
              strokeLinecap="round"
              strokeWidth="1"
              className="infrastructure"
            />
            <g
              id="Rechteck_69"
              data-name="Rechteck 69"
              transform="translate(450 4833)"
              fill="none"
              stroke="#707070"
              strokeWidth="1"
              className="infrastructure"
            >
              <rect
                className="infrastructure"
                width="631"
                height="247"
                rx="32"
                stroke="none"
              />
              <rect
                x="0.5"
                y="0.5"
                width="630"
                height="246"
                rx="31.5"
                fill="none"
                className="infrastructure"
              />
            </g>
          </g>
        </svg>
      </div>

      <div className="textsection lax">
        <h1
          className="section3title lax"
          data-lax-opacity="0 0, 1900 0, 2000 1"
          data-lax-opacity_small="0 0, 1500 0, 1620 1"
          data-lax-translate-y_small="0 -200, 1500 -300, 1700 -400"
        >
          Wie machen wir das
        </h1>
        <h1
          className="section3bgtitle lax"
          data-lax-opacity="0 0, 1900 0, 2000 0, 2050 1"
          data-lax-opacity_small="0 0, 1620 0, 1690 1"
          data-lax-translate-y_small="0 -190, 1500 -290, 1700 -390"
        >
          Analyse und Strategie
        </h1>
        <p
          className="section3p lax"
          data-lax-opacity="0 0, 1900 0, 2000 0, 2050 0, 2100 0.5"
          data-lax-opacity_small="0 0, 1690 0, 1730 1"
          data-lax-translate-y_small="0 -210, 1500 -310, 1700 -410"
        >
          Gemeinsam mit unseren Kunden erarbeiten wir strategische Projektziele
          basierend auf fundierter Insight-Analyse.{" "}
          <span
            className="lax"
            data-lax-opacity_small="0 0, 2730 0, 2800 1"
            data-lax-translate-y_small="0 400, 1500 1400, 1700 2400"
          >
            Der Mensch mit seinen individuellen Bedürfnissen steht dabei im
            Mittelpunkt unserer Überlegungen – denn nur sо entstehen nachhaltig
            positive Beziehungen zwischen Marken und ihren Kunden.
          </span>
        </p>
        <h1
          className="section3bgSubtitle lax"
          data-lax-opacity_large="0 0, 1900 0, 2050 0, 2500 1"
          data-lax-opacity_small="0 0, 1900 0, 1925 1, 2580 0"
          data-lax-translate-y_small="0 1, 1900 5, 2300 5"
          data-lax-translate-x_small="0 0, 1900 0, 2300 0, 2580 -3456"
        >
          Customer Analytics
        </h1>
        <p
          className="section3subp lax"
          data-lax-opacity_large="0 0, 1900 0, 2000 0, 2050 0, 2500 0.5"
          data-lax-opacity_small="0 0, 1925 0, 1960 1, 2580 0"
          data-lax-translate-y_small="0 1, 1900 0, 2300 0"
          data-lax-translate-x_small="0 0, 1900 0, 2300 0, 2580 -3456"
        >
          Customer Analytics bezeichnet die ganzheitliche Kundenanalyse, um
          wertvolle Einblicke in das Kundenverhalten und Kundenmerkmale zu
          erhalten. Intelligente Customer Analytics bietet die Grundlage für ein
          tiefgehendes Kundenverständnis, aus dem sich effiziente Marketing- und
          Vertriebsmaßnahmen für eine personalisierte Customer Journey ableiten
          lassen.{" "}
        </p>
      </div>
    </div>
  );
}
