import React from "react";

export default function FirstSection() {
  return (
    <div className="firstSection">
      <div className="lineOne">Boost.</div>
      <div className="lineTwo">Conversion.</div>
      <div className="lineThree">UX.</div>
      <div className="divider"></div>
    </div>
  );
}
