import React from "react";
import Button from "./Button.jsx";
import { Navbar, Container, Row, Col } from "react-bootstrap";

function Header() {
  return (
    <Navbar>
      <Navbar.Brand href="#home">
        <div className="logo">Aras • Studio</div>
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <Container className="justify-content-end">
          <Row>
            <Col className="ueb">
              <Button
                name="Übersicht"
                propertName="uebersicht"
                className="uebersicht"
              />
            </Col>
            <Col className="ref">
              <Button
                name="Referenzen"
                propertName="referenz"
                className="referenz"
              />
            </Col>
            <Col className="kon">
              <Button
                name="Kontakt"
                propertName="kontakt"
                className="kontakt"
              />
            </Col>
          </Row>
        </Container>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
