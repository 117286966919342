import React from "react";

export default function Ground() {
  return (
    <div className="ground">
      <div className="top">
        <svg className="windowComponents">
          <circle
            className="circle red"
            cx="20"
            cy="10"
            r="10"
            fill="#ED6B60"
          />
          <circle
            className="circle yellow"
            cx="60"
            cy="10"
            r="10"
            fill="#F6BE4F"
          />
          <circle
            className="circle green"
            cx="100"
            cy="10"
            r="10"
            fill="#62C555"
          />
        </svg>
      </div>
      <div className="main"></div>
      <div className="button">
        <div className="buttomBase"></div>
        <div className="buttonTop"></div>
      </div>
    </div>
  );
}
