import React from "react";

function Button(props) {
  return (
    <div className="Button">
      <a href={"https://calendly.com/mahir-arass/15min"} target={"_blank"}>
        <div className="btnbase"></div>
        <div className={props.propertName}>
          <div className="btntop">
            <p>{props.name}</p>
          </div>
        </div>
      </a>
    </div>
  );
}

export default Button;
