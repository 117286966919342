import React from "react";

export default function Pointer() {
  return (
    <svg
      className="pointer"
      xmlns="http://www.w3.org/2000/svg"
      width="72.5"
      height="116"
      viewBox="0 0 72.5 116"
    >
      <path
        id="Icon_awesome-mouse-pointer"
        data-name="Icon awesome-mouse-pointer"
        class="cls-1"
        d="M68.465,74.568H44.43l12.649,30.811a4.209,4.209,0,0,1-2.14,5.437L43.8,115.67a4.008,4.008,0,0,1-5.286-2.2L26.495,84.212,6.86,104.407A4.009,4.009,0,0,1,0,101.495V4.146C0,.43,4.513-1.381,6.86,1.233L71.3,67.512A4.153,4.153,0,0,1,68.465,74.568Z"
      />
    </svg>
  );
}
