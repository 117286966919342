import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function Section7() {
  return (
    <div
      className="sevenSection lax"
    >
      <Container>
        <Row>
          <Col>
            <div className="Impressum lax"
            data-lax-translate-y_small="0 320, 5000 320, 5140 320"
            >
            <p>
              Aras Studio
              <br />
              <br />
              Openright GmbH
              <br />
              Paul-Nießen-Straße 48
              <br />
              50969 Köln
              <br />
              <br />
              <br />
              <br />
              <a href="https://openright.de/datenschutz">Datenschutz</a> | <a href="https://openright.de/impressum">Impressum</a>
            </p>
            </div>
          </Col>
          <Col>
            <div
              className="contactGround lax"
              data-lax-opacity_small="0 0, 5000 0, 5140 1"
              data-lax-translate-x_small="0 -150, 5000 -180, 5140 -180"
              data-lax-translate-y_small="0 -100, 5000 -100, 5140 -100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="75"
                height="17"
                viewBox="0 0 75 17"
                className="closeTabs lax"
              >
                <g
                  id="Gruppe_613"
                  data-name="Gruppe 613"
                  transform="translate(-450 -1211)"
                >
                  <circle
                    id="Ellipse_5"
                    data-name="Ellipse 5"
                    cx="8.5"
                    cy="8.5"
                    r="8.5"
                    transform="translate(450 1211)"
                    fill="rgba(237,107,96,0.25)"
                  />
                  <circle
                    id="Ellipse_6"
                    data-name="Ellipse 6"
                    cx="8.5"
                    cy="8.5"
                    r="8.5"
                    transform="translate(479 1211)"
                    fill="rgba(246,190,79,0.25)"
                  />
                  <ellipse
                    id="Ellipse_7"
                    data-name="Ellipse 7"
                    cx="8"
                    cy="8.5"
                    rx="8"
                    ry="8.5"
                    transform="translate(509 1211)"
                    fill="rgba(98,197,85,0.29)"
                  />
                </g>
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 266 252"
                className="avatar lax"
              >
                <defs>
                  <pattern
                    id="pattern"
                    width="1"
                    height="1"
                    patternTransform="matrix(-1, 0, 0, 1, 557.556, 0)"
                    viewBox="15.389 0 248 234"
                  ></pattern>
                  <filter
                    id="Ellipse_4"
                    x="0"
                    y="0"
                    width="266"
                    height="252"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="2" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood flood-color="snow" flood-opacity="0.494" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                </defs>
                <g
                  transform="matrix(1, 0, 0, 1, 0, 0)"
                  filter="url(#Ellipse_4)"
                >
                  <g
                    id="Ellipse_4-2"
                    data-name="Ellipse 4"
                    transform="translate(9 7)"
                    stroke="#131a2f"
                    stroke-width="35"
                    fill="none"
                  >
                    <ellipse
                      cx="124"
                      cy="117"
                      rx="124"
                      ry="117"
                      stroke="none"
                    />
                    <ellipse
                      cx="124"
                      cy="117"
                      rx="106.5"
                      ry="99.5"
                      fill="none"
                    />
                  </g>
                </g>
              </svg>
              <h3 className="actionDescription">Anrufen</h3>
              <Container className="callTActions">
                <Row>
                  <Col>
                  <a href={"https://calendly.com/mahir-arass/15min"} target={"_blank"}>
                    <div className="messageBtn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="51.71"
                        height="35.8"
                        viewBox="0 0 66.735 46.201"
                        className="messageIcon lax"
                      >
                        <g
                          id="Icon_ionic-ios-mail"
                          data-name="Icon ionic-ios-mail"
                          transform="translate(-3.375 -7.875)"
                          opacity="0.324"
                          style={{ isolation: "isolate" }}
                        >
                          <path
                            id="Pfad_42"
                            data-name="Pfad 42"
                            d="M69.565,10.412,52.3,27.995a.31.31,0,0,0,0,.449L64.383,41.31a2.082,2.082,0,0,1,0,2.952,2.091,2.091,0,0,1-2.952,0L49.4,31.444a.329.329,0,0,0-.465,0L46,34.427a12.916,12.916,0,0,1-9.208,3.882,13.175,13.175,0,0,1-9.4-3.994l-2.823-2.872a.329.329,0,0,0-.465,0L12.07,44.261a2.091,2.091,0,0,1-2.952,0,2.082,2.082,0,0,1,0-2.952L21.2,28.444a.342.342,0,0,0,0-.449L3.92,10.412a.316.316,0,0,0-.545.225v35.18a5.149,5.149,0,0,0,5.133,5.133H64.977a5.149,5.149,0,0,0,5.133-5.133V10.637A.321.321,0,0,0,69.565,10.412Z"
                            transform="translate(0 3.125)"
                            fill="#fff"
                          />
                          <path
                            id="Pfad_43"
                            data-name="Pfad 43"
                            d="M35.652,37.248a8.721,8.721,0,0,0,6.272-2.631L67.095,9a5.042,5.042,0,0,0-3.176-1.123H7.4A5.009,5.009,0,0,0,4.226,9L29.4,34.617A8.723,8.723,0,0,0,35.652,37.248Z"
                            transform="translate(1.09)"
                            fill="#fff"
                          />
                        </g>
                      </svg>
                    </div>
                    </a>
                  </Col>
                  <Col>
                  <a href={"https://calendly.com/mahir-arass/15min"} target={"_blank"}>
                    <div className="callBtn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="34.363"
                        height="34.36"
                        viewBox="0 0 34.363 34.36"
                        className="callIcon lax"
                      >
                        <path
                          id="Icon_ionic-ios-call"
                          data-name="Icon ionic-ios-call"
                          d="M37.86,31.407a28.863,28.863,0,0,0-6.029-4.034c-1.807-.868-2.469-.85-3.748.072-1.065.769-1.753,1.485-2.979,1.217s-3.641-2.093-5.984-4.428-4.169-4.759-4.428-5.984.456-1.914,1.217-2.979c.921-1.279.948-1.941.072-3.748a28.292,28.292,0,0,0-4.034-6.029c-1.315-1.315-1.61-1.029-2.335-.769A13.286,13.286,0,0,0,7.473,5.859a6.449,6.449,0,0,0-2.567,2.71c-.51,1.1-1.1,3.149,1.905,8.5a47.419,47.419,0,0,0,8.337,11.119h0l.009.009.009.009h0a47.6,47.6,0,0,0,11.119,8.337c5.349,3.006,7.4,2.415,8.5,1.905a6.34,6.34,0,0,0,2.71-2.567,13.286,13.286,0,0,0,1.136-2.138C38.889,33.017,39.184,32.722,37.86,31.407Z"
                          transform="translate(-4.49 -4.502)"
                          fill="#fff"
                        />
                      </svg>
                    </div>
                    </a>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
