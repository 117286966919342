import React from "react";

export default function FifthSection() {
  return (
    <div className="fifthSection">
      <h3
        className="lax"
        data-lax-opacity="0 0, 3600 0, 4220 1"
        data-lax-opacity_small="0 0, 3200 0, 3569 1"
      >
        Verbessertes Nutzererlebnis
      </h3>
      <h5
        className="lax"
        data-lax-opacity="0 0, 3600 0, 4220 1"
        data-lax-opacity_small="0 0, 3569 0, 3590 1"
      >
        Optimales Interface Design{" "}
      </h5>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 688 745"
        className="groundSC lax"
        data-lax-opacity="0 0, 3600 0, 4270 1"
        data-lax-rotate-y="0 0, 4200 0, 4660 45"
        data-lax-rotate-x="0 0, 4200 0, 4660 50"
        data-lax-translate-y="0 0, 4200 0, 4660 200"
        data-lax-scale="0 1, 4200 1, 6260 0"

        data-lax-opacity_small="0 0, 3600 0, 3710 1"
        data-lax-rotate-y_small="0 0, 3700 0, 4100 45"
        data-lax-rotate-x_small="0 0, 3700 0, 4100 50"
        data-lax-translate-y_small="0 0, 3700 0, 4360 200"
        data-lax-scale_small="0 1, 3700 1, 6260 0"
      >
        <g
          id="Gruppe_607"
          data-name="Gruppe 607"
          transform="translate(-1171 -9082)"
        >
          <rect
            id="Rechteck_72"
            data-name="Rechteck 72"
            width="745"
            height="688"
            rx="12"
            transform="translate(1859 9082) rotate(90)"
            fill="#fff"
            opacity="0.023"
          />
          <g id="Gruppe_608" data-name="Gruppe 608">
            <circle
              id="Ellipse_51"
              data-name="Ellipse 51"
              cx="10"
              cy="10"
              r="10"
              transform="translate(1199 9109)"
              fill="rgba(237,107,96,0.25)"
            />
            <circle
              id="Ellipse_52"
              data-name="Ellipse 52"
              cx="10"
              cy="10"
              r="10"
              transform="translate(1233 9109)"
              fill="rgba(246,190,79,0.25)"
            />
            <circle
              id="Ellipse_53"
              data-name="Ellipse 53"
              cx="10"
              cy="10"
              r="10"
              transform="translate(1267 9109)"
              fill="rgba(98,197,85,0.29)"
            />
          </g>
        </g>
      </svg>

      <div
        className="tapGesture lax"
        data-lax-opacity="0 0, 3600 0, 4270 1, 4500 0"

        data-lax-opacity_small="0 0, 3650 0, 3707 1"
        data-lax-translate-y_small="0 0, 3400 -680, 3707 -670, 4000 -400"
        data-lax-translate-x_small="0 160, 3400 160"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="97"
          height="94"
          viewBox="0 0 97 94"
          className="tapGround1 lax"
        >
          <g
            id="Gruppe_609"
            data-name="Gruppe 609"
            transform="translate(-567 -343)"
          >
            <g
              id="Ellipse_57"
              data-name="Ellipse 57"
              transform="translate(583 359)"
              fill="none"
              stroke="#9f6161"
              strokeWidth="12"
              opacity="0.798"
              className="tapGroundLine"
            >
              <ellipse
                className="tapGroundLineStroke"
                cx="32.5"
                cy="31"
                rx="32.5"
                ry="31"
                stroke="none"
              />
              <ellipse
                className="tapGroundLineFill"
                cx="32.5"
                cy="31"
                rx="26.5"
                ry="25"
                fill="none"
              />
            </g>
            <g
              id="Ellipse_58"
              data-name="Ellipse 58"
              transform="translate(567 343)"
              fill="none"
              stroke="#9f6161"
              strokeWidth="4"
              opacity="0"
              className="tapGroundOutline"
            >
              <ellipse cx="48.5" cy="47" rx="48.5" ry="47" stroke="none" />
              <ellipse cx="48.5" cy="47" rx="46.5" ry="45" fill="none" />
            </g>
          </g>
        </svg>
      </div>
      <div
        className="section5Title lax"
        data-lax-opacity="0 0, 4300 0, 4800 1"
        //data-lax-translate-y="0 0, 4800 -10, 5200 -3589"

        data-lax-opacity_small="0 0, 3900 0, 4085 1, 4300 0"
      >
        <h2>User Experience</h2>
        <p>
          Kreativität in Verbindung mit UX sind wesentliche Komponenten für die
          Kreation innovativer Produkte sowie für die Umsetzung und Gestaltung
          von Werbung, Grafik, Text und Angeboten. Mit Hilfe von Smart Data
          erstellen wir angepasste Strategien und Inhalte für die optimale
          Zusammenarbeit und Umsetzung von Ideen unter Berücksichtigung aller
          Bedürfnisse unserer Kunden.
        </p>
      </div>
    </div>
  );
}
