import React from "react";
import ReactDOM from "react-dom";
import anime from "animejs";

import App from "./App";

const rootElement = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  rootElement
);

var tl = anime.timeline({
  easing: "easeInOutQuad",
  duration: 650
});

tl.add({
  targets: ".ground",
  opacity: ["0%", "100%"],
  // borderRadius: ["0px", "40px"],
borderRadius: ["0px","26px"],
  backgroundColor: ["#131a2f", "#121A31", "#4a5573"],
  boxShadow: ["0 0 0 rgb(0, 0, 0, 0,2)", "0 12px 25px rgb(0, 0, 0)"],
  scale: {
    value: [0.3, 1],
    duration: 1800,
    delay: 300,
    easing: "easeInOutQuart"
  },
  duration: 1200,
  easing: "easeInOutQuad"
})
  .add({
    targets: ".holyScreen",
    opacity: ["0%", "3%"],
    easing: "easeInOutQuad"
  })
  .add({
    targets: ".main",
    opacity: ["0%", "100%"],
    scale: {
      value: [0.5, 1],
      duration: 1500,
      delay: 100,
      easing: "easeInOutQuart"
    },
    backgroundColor: ["#4a5573", "#959fba"],
    easing: "easeInOutQuad"
  })
  .add({
    targets: ".buttonTop",
    opacity: ["0%", "100%"],
    backgroundColor: ["#4a5573", "#959fba"],
    easing: "easeInOutQuad"
  })
  .add({
    targets: ".buttomBase",
    opacity: ["0%", "100%"],
    backgroundColor: ["#959fba", "rgba(255, 255, 255, 0.44)"],
    easing: "easeInOutQuad"
  })
  .add({
    targets: ".top",
    opacity: ["0%", "100%"],
    easing: "easeInOutQuad"
  })
  .add({
    targets: ".buttonTop",
    backgroundColor: ["#959fba", "#9f6161"],
    easing: "easeInOutExpo"
  });

var pz = anime.timeline({
  easing: "easeInOutQuad",
  duration: 3050
});

pz.add({
  targets: ".btntop",
  opacity: ["0%", "100%"],
  backgroundColor: ["#131a2f", "#131a2f"],
  easing: "easeInOutQuad"
}).add({
  targets: ".btnbase",
  opacity: ["0%", "100%"],
  backgroundColor: ["#131a2f", "rgba(255, 255, 255, 0.36)"],
  easing: "easeInOutQuad"
});

var pros = anime.timeline({
  easing: "easeInOutQuad",
  duration: 650
});

pros
  .add({
    targets: ".progressbar",
    opacity: ["0%", "100%"],
    backgroundColor: ["#131a2f", "#959fba"],
    delay: 2000,
    easing: "easeInOutQuad"
  })
  .add({
    targets: ".progressstatus",
    width: ["10%", "95%"], // -> from '28px' to '100%',
    easing: "easeInOutQuad",
    direction: "alternate",
    duration: 4000,
    loop: false
  });

function logFinished() {
  anime.set(".star .path", { fill: "#BC8453" });
  anime.set(".buttonTop", { boxShadow: "inset 4px 5px 5px rgba(0,0,0,0.3)" });
  anime.set(".buttomBase", { translateX: 10, translateY: 10, opacity: "0%" });
  anime.set(".Button .kontakt .btntop", {
    boxShadow: "3px 5px 12px rgba(0, 0, 0, 0.35)"
  });
}

var point = anime.timeline({
  duration: 4350
});

point
  .add({
    targets: ".pointer",
    opacity: ["0%", "100%"],
    easing: "easeInOutQuad"
  })
  .add({
    //anime()
    targets: ".pointer",
    translateX: -170,
    translateY: -496, // -> '250px',
    easing: "linear",
    duration: 2000,
    delay: 1000,
    loop: false
  });

point.finished.then(logFinished);

anime({
  targets: ".trafficCount",
  innerHTML: [129.987, 149.987],
  easing: "linear",
  duration: 5000000,
  delay: function (el, i) {
    return i * 550;
  },
  round: 1000,
  loop: true
});

anime({
  targets: ".conversionCount",
  innerHTML: [89.987, 109.987],
  easing: "linear",
  duration: 500000,
  delay: function (el, i) {
    return i * 550;
  },
  round: 1000,
  loop: true
});

anime({
  targets: ".webTraffic",
  strokeDashoffset: [anime.setDashoffset, 0],
  easing: "easeInOutExpo",
  duration: 5000,
  delay: function (el, i) {
    return i * 550;
  },
  direction: "alternate",
  loop: true
});

anime({
  targets: ".webConversionCount",
  strokeDashoffset: [anime.setDashoffset, 0],
  easing: "easeInOutExpo",
  duration: 5000,
  delay: function (el, i) {
    return i * 550;
  },
  direction: "alternate",
  loop: true
});

anime({
  targets: ".infrastructure",
  strokeDashoffset: [anime.setDashoffset, 0],
  easing: "easeInOutExpo",
  duration: 5000,
  delay: function (el, i) {
    return i * 550;
  },
  direction: "alternate",
  loop: true
});

// anime({
//   targets: ".tapGround1",
//   height: ["118", "62"],
//   width: ["124", "65"],
//   easing: "easeInOutQuad",
//   duration: 2900,
//   loop: true
// });

// anime({
//   targets: ".tapGroundLine",
//   strokeWidth: ["5", "12"],
//   opacity: ["0", "0.798"],
//   easing: "easeInOutQuad",
//   duration: 2900,
//   loop: true
// });

// anime({
//   targets: ".tapGroundLineStroke",
//   cx: ["62", "32.5"],
//   cy: ["59", "31"],
//   rx: ["62", "32.5"],
//   ry: ["59", "31"],
//   easing: "easeInOutQuad",
//   duration: 2900,
//   loop: true
// });
// anime({
//   targets: ".tapGroundLineFill",
//   cx: ["62", "32.5"],
//   cy: ["59", "31"],
//   rx: ["62", "26.5"],
//   ry: ["59", "25"],
//   easing: "easeInOutQuad",
//   duration: 2900,
//   loop: true
// });

var tapGorund = anime.timeline({
  duration: 1900,
  easing: "easeInOutQuad",
  loop: true
});

tapGorund

  .add({
    targets: ".tapGround1",
    scale: 2,
    opacity: ["0.798", "0"],
  })

  .add({
    targets: ".tapGroundOutline",
    opacity: ["0.3", "0"],
    delay: 800
  });

anime({
  targets: ".tapGroundLine",
  strokeWidth: ["12", "5"],
  easing: "easeInOutQuad",
  duration: 3200,
  loop: true
});

// anime({
//     targets: ".tapGroundOutline",
//     opacity: ["0", "100"],
//     easing: "easeInOutQuad",
//     duration: 3200,
//     delay: 1000,
//     loop: true
//   });
