import React from "react";

export default function SecondSection() {
  return (
    <div
      className="secondsection lax"
      data-lax-opacity_large="0 0, 700 0, 900 1"
      data-lax-opacity_small="0 0, 400 0, 500 1"
    >
      <p className="firstLabel">Nachhaltige Umsatzsteigerung</p>
      <p className="secondLabel">mit Growth Hacking und Customer Experience</p>
      <div>
        <div
          className="screenInSecond lax"
          data-lax-scale-y="0 1, 920 1, 1029 14 | speed=-0,1"
          data-lax-scale-x="0 1, 920 1, 1029 1 | speed=-0,1"
          data-lax-translate-y="0 1, 920 1, 1029 35 | speed=-0,1"
          data-lax-opacity="0 0.02, 920 0.02, 1029 0.04, 2000 0"
        ></div>
        <svg
          className="screenActions lax"
          data-lax-opacity="0 0, 1000 0, 1700 1"
          data-lax-translate-y_small="0 1, 900 75, 1200 260"
          data-lax-translate-x_small="0 1, 900 5, 1200 15"
          style={{ marginTop: "62px", marginLeft: "10px" }}
        >
          <circle className="circle" cx="20" cy="10" r="10" fill="#ED6B60" />
          <circle className="circle" cx="60" cy="10" r="10" fill="#F6BE4F" />
          <circle className="circle" cx="100" cy="10" r="10" fill="#62C555" />
        </svg>
        <div
          className="section2Title lax"
          data-lax-opacity="0 0, 1000 0, 1500 1"
          data-lax-opacity_small="0 0, 700 0, 1200 1"
          data-lax-translate-y_large="0 1, 1570 -10, 1600 -3589"
          data-lax-translate-y_small="0 100, 1200 500, 1300 400, 1310 -3589"
          data-lax-translate-x_small="0 0, 1200 0, 1300 0, 1310 -3589"
        >
          <h2>Customer Experience an allen Touchpoints</h2>
          <p>
            Mit analytischem und technologischem Know-how unterstützen wir
            unsere Kunden bei der digitalen Transformation ihrer
            Geschäftsmodelle, tragen zu Neugeschäfts- und Kundengewinnung bei
            und optimieren Kundenwert und -beziehungen.{" "}
            <span className="mobileText">
              Dabei eint uns der Anspruch, die Effizienz und die Effektivität
              aller digitalen Vertriebs- und Marketingaktivitäten von Kunden
              (B2C & B2B) nachhaltig zu verbessern.
            </span>
          </p>
        </div>
        <div
          className="section2graph1 lax"
          data-lax-opacity="0 0, 1000 0, 1500 1, 1550 0.2, 2000 0"
          data-lax-translate-y="0 0, 1570 -50, 1600 -200"
          data-lax-translate-x="0 1, 1570 -100, 1600 -200"
          //data-lax-scale-y="0 0, 1570 1, 1600 1 | speed=-0,1"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="227.766"
            height="95.009"
            viewBox="0 0 227.766 95.009"
            className="webTrafficCon"
          >
            <path
              id="Pfad_49"
              data-name="Pfad 49"
              className="webTraffic"
              d="M3531.343,2571s-1.545-67.443,18.685-51.893,30.69-80.7,52.679-21.7,41.1-9.6,63.441-7.477,25.911,15.951,25.911,15.951h39.477l24.194-21.283"
              transform="translate(-3529.284 -2476.035)"
            />
          </svg>
          <h2 className="trafficCount">143.675</h2>
          <h4 className="trafficCountName">Aktuelle Besucher</h4>
        </div>

        <div
          className="section3graph1 lax"
          data-lax-opacity="0 0, 1400 0, 1600 1, 1900 0, 2350 0"
          data-lax-translate-y="0 0, 1670 -100, 1900 500"
          data-lax-translate-x="0 1, 1670 10, 1900 20"
          
          data-lax-opacity_small="0 0, 1304 0, 1312 1, 1430 1, 1730 0"
          data-lax-translate-y_small="0 0, 1500 100, 1600 780"
          data-lax-translate-x_small="0 0, 1312 100, 1730 100"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="262.344"
            height="104.881"
            viewBox="0 0 262.344 104.881"
            className="webConversion lax"
            data-lax-opacity="0 0, 1400 0, 1600 1, 1700 0"
            data-lax-opacity_small="0 0, 1304 0, 1312 1, 1700 0"
          >
            <path
              id="Pfad_48"
              data-name="Pfad 48"
              className="webConversionCount"
              d="M3531.347,2585.176s18.188-26.658,41.539-8.709,15.451-144.334,40.832-76.228c8.2,22,11.119,22.784,26.4,22.784s14.747-20.281,34.731-22.784c29.519-3.7,21.531-13.832,43.422-11.386s29.2,18.5,43.84,18.5,28.226-21.9,28.226-21.9"
              transform="translate(-3529.695 -2481.422)"
              fill="none"
              stroke="#fff"
              strokeWidth="4"
            />
          </svg>
          <h2
            className="conversionCount lax"
            data-lax-opacity="0 0, 1400 0, 1600 1, 1700 0"
            data-lax-opacity_small="0 0, 1304 0, 1312 1, 1700 0"
          >
            103.675
          </h2>
          <h4
            className="conversionCountName lax"
            data-lax-opacity="0 0, 1400 0, 1600 1, 1700 0"
            data-lax-opacity_small="0 0, 1304 0, 1312 1, 1700 0"
          >
            Konversionen
          </h4>
        </div>
        <div
          className="section2Title sub lax"
          data-lax-opacity="0 0, 1500 0, 1600 1"
          data-lax-translate-y="0 -10, 1670 -100, 1900 -700"
          
          data-lax-opacity_small="0 0, 1310 0, 1330 1"
          data-lax-translate-y_small="0 0, 1365 -100, 1500 -100"
          data-lax-translate-x_small="0 0, 1310 0, 1390 0, 1500 -3589"

        >
          <h2>Ziel: Kundengewinnung</h2>
          <p>
            Unser Ziel ist es, ein möglichst nahtloses, konsistentes digitales
            Markenerlebnis zu bieten – geräteübergreifend entlang der gesamten
            Customer Journey.
          </p>
        </div>
      </div>
    </div>
  );
}
