import React from "react";
import { useLax } from "use-lax";
import { Container, Row, Col } from "react-bootstrap";
import Ground from "./Ground.jsx";
import Screen from "./Screen.jsx";
import Header from "./Header.jsx";
import Progressbar from "./Progressbar";
import Pointer from "./Pointer";
import Stars from "./Stars";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
//import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons";

import "./styles.css";

export default function App() {
  useLax({
    breakpoints: { small: 0, large: 992 }
  });
  return (
    <div className="App">
      <Header />
      <Container className="mobile">
        Boost. <span>Conversion.</span> UX.
      </Container>
      <Container>
        <Row>
          <Col>
            <Ground />
          </Col>
          <Col className="desktop">
            <Section1 />
          </Col>
        </Row>
      </Container>
      <Container>
        <Screen />
        <Container>
          <Progressbar />
          <Stars />
        </Container>
      </Container>
      <Pointer />
      <Container>
        <Section2 />
      </Container>
      <Container>
        <Section3 />
      </Container>
      <Container>
        <Section4 />
      </Container>
      <Container>
        <Section5 />
      </Container>
      <Container>
        <Section6 />
      </Container>
      <Container>
        <Section7 />
      </Container>
    </div>
  );
}
