import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function FourthSection() {
  return (
    <div
      className="fourthSection lax"
      data-lax-opacity="0 0, 2600 0, 2900 0, 3100 1"
      data-lax-opacity_small="0 0, 2329 0, 2575 1"
    >
      <h1
        className="fourthTitle lax"
        data-lax-opacity="0 0, 3000 0, 3200 0, 3300 1"
        data-lax-opacity_small="0 0, 2329 0, 2575 1"
      >
        Voll integrierte Lösungen
      </h1>
      <h1
        className="fourthSubTitle lax"
        data-lax-opacity="0 0, 3300 0, 3600 1"
        data-lax-opacity_small="0 0, 2575 0, 2605 1"
      >
        Wir bieten Ihnen alles, was Sie benötigen.
      </h1>

      <div
        className="serviceSection lax"
        data-lax-opacity="0 0, 3300 0, 3500 1"
        data-lax-opacity_small="0 0, 2605 0, 2705 1"
      >
        <Container>
          <Row>
            <Col>
              <div
                className="ads lax"
                data-lax-present="fadeIn"
                data-lax-translate-y_small="0 60, 2618 120"
                data-lax-translate-x_small="0 30, 2618 30"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 148.403 126.56"
                >
                  <defs>
                    <filter
                      id="Icon_material-web-asset"
                      x="0"
                      y="0"
                      width="148.403"
                      height="126.56"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset dx="12" dy="12" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood floodOpacity="0.161" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g
                    transform="matrix(1, 0, 0, 1, 0, 0)"
                    filter="url(#Icon_material-web-asset)"
                  >
                    <path
                      id="Icon_material-web-asset-2"
                      data-name="Icon material-web-asset"
                      d="M116.858,6H18.545C10.75,6,4.5,11.881,4.5,19.07V97.49c0,7.188,6.25,13.07,14.045,13.07h98.314c7.725,0,14.045-5.882,14.045-13.07V19.07C130.9,11.881,124.653,6,116.858,6Zm0,91.49H18.545V32.14h98.314Z"
                      transform="translate(-4 -5.5)"
                      fill="#131a2f"
                      stroke="#7b86a2"
                      strokeWidth="1"
                    />
                  </g>
                </svg>

                <h4 className="adsTitle">SEA und SEO</h4>
                <h4 className="adsTitle2">Social Media Ads</h4>
              </div>
            </Col>
            <Col>
              <div
                className="uxui lax"
                data-lax-present="fadeIn"
                data-lax-translate-y_small="0 100, 2726 400"
                data-lax-translate-x_small="0 -85, 2726 -85"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 86.307 112.03"
                >
                  <defs>
                    <filter
                      id="Icon_material-touch-app"
                      x="0"
                      y="0"
                      width="86.307"
                      height="112.03"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset dx="12" dy="12" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood floodOpacity="0.161" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g
                    transform="matrix(1, 0, 0, 1, 0, 0)"
                    filter="url(#Icon_material-touch-app)"
                  >
                    <path
                      id="Icon_material-touch-app-2"
                      data-name="Icon material-touch-app"
                      d="M25.345,39.826V23.792a10.718,10.718,0,1,1,21.436,0V39.826a19.292,19.292,0,1,0-21.436,0ZM67.531,59.675,48.067,49.986a6.037,6.037,0,0,0-2.315-.472H42.494V23.792a6.431,6.431,0,0,0-12.861,0V69.836l-14.7-3.087a7.493,7.493,0,0,0-1.029-.129,4.8,4.8,0,0,0-3.387,1.415l-3.387,3.43L28.3,92.643a6.453,6.453,0,0,0,4.544,1.886h29.11a6.244,6.244,0,0,0,6.173-5.488l3.215-22.593a6.162,6.162,0,0,0-3.816-6.774Z"
                      transform="translate(-6.63 -4)"
                      fill="#131a2f"
                      stroke="#7b86a2"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                  </g>
                </svg>

                <h4 className="uxuiTitle">User Experience |</h4>
                <h4 className="uxuiTitle2">User Interface Design</h4>
              </div>
            </Col>
            <Col>
              <div
                className="dev lax"
                data-lax-present="fadeIn"
                data-lax-translate-y_small="0 200, 3000 700"
                data-lax-translate-x_small="0 -203, 3000 -203"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 147.3 105.533"
                >
                  <defs>
                    <filter
                      id="Icon_material-devices"
                      x="0"
                      y="0"
                      width="147.3"
                      height="105.533"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset dx="12" dy="12" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood floodOpacity="0.161" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g
                    transform="matrix(1, 0, 0, 1, 0, 0)"
                    filter="url(#Icon_material-devices)"
                  >
                    <path
                      id="Icon_material-devices-2"
                      data-name="Icon material-devices"
                      d="M20.883,16.442h93.975V6H20.883A10.472,10.472,0,0,0,10.442,16.442V73.871H0V89.533H73.092V73.871H20.883Zm99.2,10.442H88.754A5.236,5.236,0,0,0,83.533,32.1V84.313a5.236,5.236,0,0,0,5.221,5.221h31.325a5.236,5.236,0,0,0,5.221-5.221V32.1A5.236,5.236,0,0,0,120.079,26.883Zm-5.221,46.988H93.975V37.325h20.883Z"
                      transform="translate(0.5 -5.5)"
                      fill="#131a2f"
                      stroke="#7b86a2"
                      strokeWidth="1"
                    />
                  </g>
                </svg>

                <h4 className="devTitle">Webentwicklung</h4>
                <h4 className="devTitle2">Appentwicklung</h4>
              </div>
            </Col>
          </Row>
        </Container> 
      </div>
    </div>
  );
}
