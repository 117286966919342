import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function Star() {
  return (
    <Container className="starsContainer">
      <Row xs={5} md={4} lg={5}>
        <Col md="auto" height="auto">
          <svg
            className="star"
            xmlns="http://www.w3.org/2000/svg"
            width="42.021"
            height="40.25"
            viewBox="0 0 42.021 40.25"
          >
            <path
              className="path"
              id="Icon_awesome-star"
              data-name="Icon awesome-star"
              d="M19.73,1.363l-5,10.14L3.54,13.135a2.452,2.452,0,0,0-1.356,4.182l8.1,7.888L8.365,36.348a2.449,2.449,0,0,0,3.554,2.581l10.01-5.261,10.01,5.261a2.451,2.451,0,0,0,3.554-2.581L33.577,25.2l8.1-7.888a2.452,2.452,0,0,0-1.356-4.182L29.127,11.5l-5-10.14a2.453,2.453,0,0,0-4.4,0Z"
              transform="translate(-0.918 0.501)"
              fill="none"
              stroke="#000"
              strokeWidth="1"
            />
          </svg>
        </Col>
        <Col md="auto" height="auto">
          <svg
            className="star"
            xmlns="http://www.w3.org/2000/svg"
            width="42.021"
            height="40.25"
            viewBox="0 0 42.021 40.25"
          >
            <path
              className="path"
              id="Icon_awesome-star"
              data-name="Icon awesome-star"
              d="M19.73,1.363l-5,10.14L3.54,13.135a2.452,2.452,0,0,0-1.356,4.182l8.1,7.888L8.365,36.348a2.449,2.449,0,0,0,3.554,2.581l10.01-5.261,10.01,5.261a2.451,2.451,0,0,0,3.554-2.581L33.577,25.2l8.1-7.888a2.452,2.452,0,0,0-1.356-4.182L29.127,11.5l-5-10.14a2.453,2.453,0,0,0-4.4,0Z"
              transform="translate(-0.918 0.501)"
              fill="none"
              stroke="#000"
              strokeWidth="1"
            />
          </svg>
        </Col>
        <Col md="auto" height="auto">
          <svg
            className="star"
            xmlns="http://www.w3.org/2000/svg"
            width="42.021"
            height="40.25"
            viewBox="0 0 42.021 40.25"
          >
            <path
              className="path"
              id="Icon_awesome-star"
              data-name="Icon awesome-star"
              d="M19.73,1.363l-5,10.14L3.54,13.135a2.452,2.452,0,0,0-1.356,4.182l8.1,7.888L8.365,36.348a2.449,2.449,0,0,0,3.554,2.581l10.01-5.261,10.01,5.261a2.451,2.451,0,0,0,3.554-2.581L33.577,25.2l8.1-7.888a2.452,2.452,0,0,0-1.356-4.182L29.127,11.5l-5-10.14a2.453,2.453,0,0,0-4.4,0Z"
              transform="translate(-0.918 0.501)"
              fill="none"
              stroke="#000"
              strokeWidth="1"
            />
          </svg>
        </Col>
        <Col md="auto" height="auto">
          <svg
            className="star"
            xmlns="http://www.w3.org/2000/svg"
            width="42.021"
            height="40.25"
            viewBox="0 0 42.021 40.25"
          >
            <path
              className="path"
              id="Icon_awesome-star"
              data-name="Icon awesome-star"
              d="M19.73,1.363l-5,10.14L3.54,13.135a2.452,2.452,0,0,0-1.356,4.182l8.1,7.888L8.365,36.348a2.449,2.449,0,0,0,3.554,2.581l10.01-5.261,10.01,5.261a2.451,2.451,0,0,0,3.554-2.581L33.577,25.2l8.1-7.888a2.452,2.452,0,0,0-1.356-4.182L29.127,11.5l-5-10.14a2.453,2.453,0,0,0-4.4,0Z"
              transform="translate(-0.918 0.501)"
              fill="none"
              stroke="#000"
              strokeWidth="1"
            />
          </svg>
        </Col>
        <Col md="auto" height="auto">
          <svg
            className="star"
            xmlns="http://www.w3.org/2000/svg"
            width="42.021"
            height="40.25"
            viewBox="0 0 42.021 40.25"
          >
            <path
              className="path"
              id="Icon_awesome-star"
              data-name="Icon awesome-star"
              d="M19.73,1.363l-5,10.14L3.54,13.135a2.452,2.452,0,0,0-1.356,4.182l8.1,7.888L8.365,36.348a2.449,2.449,0,0,0,3.554,2.581l10.01-5.261,10.01,5.261a2.451,2.451,0,0,0,3.554-2.581L33.577,25.2l8.1-7.888a2.452,2.452,0,0,0-1.356-4.182L29.127,11.5l-5-10.14a2.453,2.453,0,0,0-4.4,0Z"
              transform="translate(-0.918 0.501)"
              fill="none"
              stroke="#000"
              strokeWidth="1"
            />
          </svg>
        </Col>
      </Row>
    </Container>
  );
}
